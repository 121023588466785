<template>
    <div class="pa-4">
        <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            :search="search"
        >
            <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Roles</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>                
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click="dialog=true" v-if="$store.state.currentPagePriv.create">
                  New Role
                </v-btn>
                <v-dialog v-model="dialog" v-if="dialog" max-width="90%" scrollable>
                <v-card>
                    <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text style="height: 90%;">
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <span>Code</span>
                            <v-text-field maxlength="3" v-model="editedItem.code" filled rounded dense hide-details="auto" @keydown.space.prevent
                              :readonly="editedIndex>-1" :rules="reqRule">
                              </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <span>Role Name</span>
                            <v-text-field v-model="editedItem.role_name" filled rounded dense hide-details="auto" :rules="reqRule"></v-text-field>
                        </v-col>
                        </v-row>
                        <div v-for="(parent, idx) in menuparents" :key="idx" style="">
                          <!-- <v-divider class="my-2"></v-divider>        -->
                          <div class="font-weight-bold body-1 mt-3">
                            <v-row>
                              <v-col cols="4">{{parent}}</v-col>
                              <v-col cols="2">View</v-col>
                              <v-col cols="2">Create</v-col>
                              <v-col cols="2">Update</v-col>
                              <v-col cols="2">Delete</v-col>
                            </v-row>
                            </div>
                          <div>
                              <v-row v-for="(obj, idx) in filterMenus(parent)" :key="idx">
                                <v-col cols="4" class="body-1">
                                  {{obj.menu_name}}
                                </v-col>
                                <v-col cols="2">
                                  <v-checkbox v-model="editedItem.menu_id" :value="obj.id" hide-details></v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                  <v-checkbox hide-details v-model="editedItem.create_priv" :value="obj.id"></v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                  <v-checkbox hide-details v-model="editedItem.update_priv" :value="obj.id"></v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                  <v-checkbox hide-details v-model="editedItem.delete_priv" :value="obj.id"></v-checkbox>
                                </v-col>
                              </v-row>
                          </div>
                        </div>
                      </v-form>
                    </v-container>
                    <!-- {{editedItem}} -->
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="body-1 text-center">Anda akan menghapus role ini?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)" v-if="$store.state.currentPagePriv.update">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)" v-if="$store.state.currentPagePriv.delete">
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Code', value: 'code' },
        { text: 'Role Name', value: 'role_name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {menu_id:[], create_priv:[], update_priv:[], delete_priv:[]},
      defaultItem: {},
      search: '',
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Role' : 'Edit Role'
      },
      menus() {
          return this.$store.state.menu.objs
      },
      menuparents() {
        return this.$store.state.menu.menuparents
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        await this.$store.dispatch('menu/getObjs')
        let url = this.$store.state.config.apiHost + "roles" 
        let response = await this.$axios.get(url, {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
        console.log(response.data.data)
        if (response.data.status=='success') this.items = response.data.data
      },

      filterMenus(parent) {
        return this.menus.filter(element => {
          return element.parent_id == parent
        });
      },

      editItem (item) {
        if (item.menu_id===null) item.menu_id = []
        else if (typeof item.menu_id !== 'object') item.menu_id = JSON.parse(item.menu_id)

        if (item.create_priv===null) item.create_priv = []
        else if (typeof item.create_priv !== 'object') item.create_priv = JSON.parse(item.create_priv)

        if (item.update_priv===null) item.update_priv = []
        else if (typeof item.update_priv !== 'object') item.update_priv = JSON.parse(item.update_priv)

        if (item.delete_priv===null) item.delete_priv = []
        else if (typeof item.delete_priv !== 'object') item.delete_priv = JSON.parse(item.delete_priv)

        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        try {
          let url = this.$store.state.config.apiHost + "roles/" + this.editedItem.code
          let response = await this.$axios.delete(url, {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          if (response.data.status=='success') this.items.splice(this.editedIndex, 1)
          this.closeDelete()
        }
        catch (err) {
          const keyword = 'Foreign key'
          if (err.response.data.message.search(keyword) > -1){
            return this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Role tidak dapat dihapus, sedang digunakan!"})
          }
          else {
            return this.$store.commit('setSnackbar', {saveError: true, errorMessage: err.response.data.message})
          }
        }

      },

      close () {
        this.valid = true
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({menu_id:[], create_priv:[], update_priv:[], delete_priv:[]}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        await this.$refs.form.validate();
        if (!this.valid) return;

        this.editedItem.menu_id = JSON.stringify(this.editedItem.menu_id)
        this.editedItem.create_priv = JSON.stringify(this.editedItem.create_priv)
        this.editedItem.update_priv = JSON.stringify(this.editedItem.update_priv)
        this.editedItem.delete_priv = JSON.stringify(this.editedItem.delete_priv)

        if (this.editedIndex > -1) {
          let url = this.$store.state.config.apiHost + "roles/" + this.editedItem.code
          let response = await this.$axios.put(url, JSON.stringify(this.editedItem), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
          if (response.data.status=='success') Object.assign(this.items[this.editedIndex], this.editedItem)

          
        } else {
          try {
            let url = this.$store.state.config.apiHost + "roles"
            let response = await this.$axios.post(url, JSON.stringify(this.editedItem), {headers:{'Authorization': 'Bearer '+ this.$store.state.token, 'Content-Type': 'application/json'}})
            if (response.data.status=='success') this.items.push(this.editedItem)
          }
          catch (err) {
            const keyword = 'duplicate key'
            if (err.response.data.message.search(keyword) > -1){
              return this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Duplicate Key! Kode sudah terpakai."})
            }
            else {
              return this.$store.commit('setSnackbar', {saveError: true, errorMessage: err.response.data.message})
            }
          }

        }
        this.close()
      },
    },
  }
</script>

<style>
.v-text-field fieldset, .v-text-field .v-input__control, .v-text-field .v-input__slot {
    border-radius: 7px!important;
}

</style>

<style>
.v-input--selection-controls {
    margin-top: 1px!important;
}
</style>